{
  "BindCard": "Bind Card",
  "BindCreditOrDebitCardForPayment": "Bind Credit or Debit Card for Payment",
  "GoBind": "Go Bind",
  "PayWithCard": "Pay with Card",
  "PayWithCreditOrDebitCard": "Pay with Credit or Debit Card",
  "RemoveAllHistory": "Remove All History",
  "SignInViewBooks": "Sign in to view all your favorite books in Library",
  "about": "ABOUT",
  "accountDontExist": "The account doesn't exist. Try a different one. You can sign in with email or user id.",
  "active": "{x}-OFF",
  "add": "Add to Library",
  "addToLibrary": "Add to Library",
  "addedToLibrary": "Added to Library",
  "addedToLibraryBtn": "Added to Library",
  "appliedOff": "{x} OFF - Applied",
  "areYou": "Are you sure to remove all selected books?",
  "areYouRemoveRecords": "Are you sure you want to remove all records? This action cannot be undone.",
  "areYouSure": "Are you sure you want to sign out?",
  "autoUnlock": "Auto unlock",
  "back": "Back",
  "background": "Background",
  "balance": "Balance",
  "beginReading": "Begin reading",
  "betterPlans": "Better plans will be available after you've signed in",
  "bonus": "Free Coins",
  "booksInTotal": "{x} books in total",
  "cancel": "Cancel",
  "cantactUs": "CONTACT US",
  "card_holder_name": "Cardholder's Name",
  "card_info": "Card Info",
  "card_number": "Card Number",
  "cards": "{x}-OFF Coupon",
  "chapter_x": "Chapter {x}",
  "cheapX": "Up to {x}%",
  "checkout": "Checkout",
  "chooseYourAccount": "Choose your account",
  "chooseYourPlan": "Choose Your Plan",
  "choose_a_coupon": "Choose a Coupon",
  "choose_a_package": "Choose a Package",
  "closeIn": "Close in:",
  "coins": "Coins",
  "coinsUnlockStories": "Coins and Free Coins can be used to unlock stories",
  "coinsUnlockThisChapter": "{x} Coins/Free Coins to unlock this chapter",
  "coins_added": "The Coins have been added to your account",
  "coins_free": "{x} Coins + {x} Free Coins",
  "completed": "Completed",
  "confirm": "Confirm",
  "confirm_your_account": "Confirm Your Account",
  "continue": "CONTINUE",
  "continueReadMore": "Continue reading to find out more wonderful stories in Tapon",
  "continue_topping_up": "Continue topping up",
  "countBooks": "{x} Books",
  "countViews": "{x} Views",
  "credit_or_debit_card": "Credit/Debit Card",
  "details": "Coins",
  "discountOffApplied": "Not use any coupons",
  "discountOffInactive": "No active coupons",
  "done": "Done",
  "edit": "Edit",
  "enterBookName": "Enter Book Name/ Author",
  "enterYouIDorEmail": "Please enter the right User ID or Email",
  "enter_card_holder_name": "Enter the cardholder's name here",
  "enteryourId": "Enter your User ID here",
  "expiration_date": "Expiration Date",
  "expiredSeeTime": "Expired. See you next time~",
  "expiresAtDate": "Expires at {x}",
  "failed": "Failed",
  "failedToLoad": "Failed to load",
  "featuredStories": "Featured Stories",
  "followUs": "FOLLOW US",
  "fontSize": "Font Size",
  "for_all_top_up_packages": "For all top-up packages",
  "for_x_only": "For {x} only",
  "for_x_or_less": "For {x} or less",
  "for_x_or_more": "For {x} or more",
  "free_coins": "Free Coins",
  "genres": "Genres",
  "genresTitle": "Genres",
  "getBouns": "Get free: {x} Free Coins",
  "getMore": "Limited-Time Extra Offer {x}",
  "getMoreCoins": "Get More Coins",
  "getTheApp": "Get the App",
  "get_free_coins": "Get Free Coins",
  "goToAppTip": "Go to App",
  "gotoTaponApp": "Go to Tapon app",
  "history": "History",
  "home": "Home",
  "hot": "Hot",
  "inactiveOff": "{x} OFF - Inactive",
  "including_free_coins": "+ {x} Free Coins",
  "iosCanAppleId": "IOS users can log in with your Apple ID.",
  "lastChapter": "Last chapter",
  "latestUpdate": "Latest Update",
  "legal": "LEGAL",
  "library": "Library",
  "limitedTimeOffer": "Limited-time offer: {x}% off",
  "limited_time_offer": "Limited-Time Offer",
  "limited_time_offers": "Limited-Time Offers",
  "list": "List",
  "loggedIn": "Signed in",
  "loginMoreStory": "Sign in to read all the wonderful stories",
  "loginTip": "By signing in, you agree to the \n {a} and {b}.",
  "more": "More",
  "moreBonusTip": "You can get more Free Coins here than in the app",
  "moreOptions": "More options",
  "moreThanApp": "{x}% More Than App's",
  "need_pay": "Price",
  "netWorkError": "Poor network connection",
  "newRelease": "New Release",
  "next": "Next",
  "nextChapter": "Next chapter",
  "noActiveCoupons": "Coupon",
  "noContent": "No content",
  "noFindServe": "Sorry. Our service is not available in your country/region yet.",
  "noResultsFound": "No results were found.",
  "notFoundUser": "The user was not found. Please re-enter.",
  "notNow": "Not now",
  "notUseAnyCoupons": "Save",
  "okay": "Okay",
  "ongoing": "Ongoing",
  "or": "Or",
  "order": "Order",
  "originalPrice": "Pay",
  "otherOptions": "Other options",
  "other_plans": "Other Plans",
  "pay": "TOP UP",
  "pay_now": "Pay Now",
  "pay_with_apple_pay": "Pay with Apple Pay",
  "pay_with_credit": "Pay with Credit / Debit Card",
  "pay_with_google_pay": "Pay with Google Pay",
  "paymentMethod": "Payment methods",
  "payment_successful": "Payment Successful",
  "platformTip": "Sign in with {x}",
  "previous": "Previous",
  "price": "Price",
  "privacyPolicy": "Privacy Policy",
  "privacy_policy_content": "",
  "privacy_policy_title": "",
  "purchaseFailed": "Purchase failed",
  "purchaseSucceeded": "Topped-up",
  "read": "Read",
  "read2": "Read",
  "readItInTheApp": "Read it in the APP",
  "readItNow": "Read it now",
  "readTheBookInLibrary": "Read the books in your Library",
  "rec": "Rec",
  "rechargeCoupon": "Original Price",
  "rechargeSave": "Details",
  "refresh": "Refresh",
  "remove": "Remove",
  "resources": "RESOURCES",
  "saveCoupon": "Save{x}",
  "save_my_card_for_future": "Save my card for future payments",
  "save_x": "Save {x}",
  "security_code": "Security Code",
  "seeMore": "More",
  "select_payment_method": "Select Payment Method",
  "signID": "Sign in with User ID",
  "signIn": "Sign in",
  "signIntoTapon": "Sign in to Tapon",
  "signOut": "Sign out",
  "signOutCancelledShep": "Your topping-up will be cancelled if you sign out.",
  "signUpAppleID": "Users who have signed up with Apple ID may sign in with User ID. {value} and check your User ID beside your profile picture in \"Me\".",
  "startIn": "Start in:",
  "start_reading_on_tapon": "Start reading on Tapon",
  "switch_account": "Switch Account",
  "synopsis": "Synopsis",
  "tags": "Tags",
  "termsOfUse": "Terms of Use",
  "title": "Tapon - an online-novel library at your fingertips. Digital reading is already a way of life.",
  "toCreate": "To create",
  "topCount": "count",
  "topUp": "Top-Up Center",
  "topUpBtn": "TOP UP",
  "tryAnother": "Try another",
  "unknownError": "Unknown Error",
  "unlockNow": "Unlock now",
  "use_new_card": "Use new card",
  "user_agreement_content": "",
  "user_agreement_title": "",
  "user_id": "User ID",
  "username": "Username",
  "vip_content": "",
  "vip_title": "",
  "welcomeAppnameTip": "Welcome to Tapon",
  "x_coins": "{x} Coins",
  "x_inactive_coupons": "{x} inactive coupons",
  "youIdIncorrect": "The user doesn't exist",
  "youMayAlsoLike": "You may also like",
  "you_will_get": "You’ll Get"
}